@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

.App {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.logo{
  width: 600px;
  margin: auto 0;
}
.message{
  font-family: 'Dancing Script', cursive;
  margin: auto;
}
